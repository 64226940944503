export const projectCategoriesList = [
    {
        category:"Mobile App Development",
        subcategories:[
            "iOS App Development",
            "Andriod App Development",
            "Cross-Platform App development"
        ]
    },
    {
        category:"Software",
        subcategories:[
            "Custom Software Development",
            "Enterprise Software Development",
            "SaaS Development",
            "API Development & Integration",
            "Software Consulting"
        ]
    },
    {
        category:"Web development",
        subcategories:[
            "Custom Web Development",
            "Web Application Development",
            "E-commerce Development",
            "CMS Development",
            "API Development & Integration"
        ]
    },
    {
        category:"Graphic Design",
        subcategories:[
            "Branding & Identity Design",
            "UI/UX Design",
            "Print Design",
            "Digital Design"
        ]
    },
    {
        category:"Network Administration and Cybersecurity",
        subcategories:[
            "Network Design & Implementation",
            "Network Security",
            "Cybersecurity Threat Detection & Response",
            "Firewall & VPN Management"
        ]
    }
]