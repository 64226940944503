
import { useEffect } from 'react';
import './App.css';
import HomePage from './pages/HomePage';
import AOS from "aos";
import "aos/dist/aos.css";
import 'react-phone-number-input/style.css'

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
   <div>
      <HomePage />
   </div>
  );
}

export default App;
